body {
	margin: 0;
	/* background-color: #f7f7f7; */
	font-family: "Hero New";
}

.App {
	min-height: 100vh;
	margin: auto;
	/* max-width: 500px; */
	overflow-x: hidden;
	overflow-y: auto;
	/* border:1px solid black; */
	/* font-family: "Times New Roman", Times, serif !important; */
	margin-bottom: 34px;
}

.User-CSS {
	min-height: 100vh;
	margin: auto;
	max-width: 500px;
	overflow-x: hidden;
	overflow-y: auto;
	/* border:1px solid black; */
	/* font-family: "Times New Roman", Times, serif !important; */
	margin-bottom: 34px;
}

/* 
div {
  font-family: 'Hero New';
} */

a {
	color: black;
}

.main-container {
	margin-top: 20px;
	min-height: 100vh;
	margin: auto;
	max-width: 500px;
	overflow-x: hidden;
	overflow-y: auto;
	/* border:1px solid black; */
	font-family: "Times New Roman", Times, serif !important;
	/* background-color: #f7f7f7; */
}

/* HOME */

.Header {
	font-family: Hero New;
	font-size: 24px;
	font-weight: 500;
	line-height: 34px;
	letter-spacing: 0em;
	text-align: left;
}

.SubHeader {
	font-family: Open Sans;
	font-size: 14px;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
	text-align: left;
}

.text {
	font-family: Open Sans;
	font-size: 10px;
	font-weight: 400;
	line-height: 14px;
	letter-spacing: 0em;
	text-align: center;
}

.text-number {
	font-family: Hero New;
	font-size: 28px;
	font-weight: 600;
	line-height: 39px;
	letter-spacing: 0em;
	text-align: center;
}

.sub-text {
	font-family: Open Sans;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	letter-spacing: 0em;
	text-align: center;
}

.circle-style {
	width: calc((100vw / 4) - 28px);
	height: calc((100vw / 4) - 28px);
	/* max-width: 150px; */
	padding: 5%;
	/* height: 0;
  padding-bottom: 20%; */
	border-radius: 50%;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #acf59a;
	justify-content: center;
	position: relative;
}

.circle-style-done {
	width: calc((100vw / 4) - 28px);
	height: calc((100vw / 4) - 28px);
	/* max-width: 150px; */
	padding: 5%;
	/* height: 0;
  padding-bottom: 20%; */
	border-radius: 50%;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	background-color: #30e700;
	justify-content: center;
	position: relative;
}

.top-right-tick {
	position: absolute;
	top: 0;
	right: 0;
	width: 20px; /* Adjust the width as needed */
	height: 20px; /* Adjust the height as needed */
}

.box-style {
	padding: 15px;
	width: 100%;
	max-width: 150px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	flex-direction: column;
	align-items: center;
	box-shadow:
		0px 1px 16px #00000014,
		inset 1px 1px 4px #ffffff40,
		inset -2px -2px 2px #00455c14;
	border-radius: 20px;
}

.box-style-2 {
	padding: 15px;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
	box-shadow:
		0px 1px 16px #00000014,
		inset 1px 1px 4px #ffffff40,
		inset -2px -2px 2px #00455c14;
	border-radius: 20px;
	align-items: center;
}

.box-style-3 {
	padding: 5%;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	/* display: flex; */
	box-shadow:
		0px 1px 16px #00000014,
		inset 1px 1px 4px #ffffff40,
		inset -2px -2px 2px #00455c14;
	border-radius: 20px;
}

/* LEAVE */

.leave-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.leave-label {
	font-family: Hero New;
	font-size: 16px;
	font-weight: 600;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
	margin-bottom: 10px;
}

.leave-input {
	width: 100%;
	padding: 8px;
	box-sizing: border-box;
	border-radius: 10px;
	margin-bottom: 10px;
	border: none;
}

button {
	border-radius: 10px;
	width: 100%;
	background-color: #30e700;
	color: black;
	padding: 5px;
	border: none;
	height: 40px;
}

/* ATTENDANCE */

.attendance-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.attendance-box {
	padding: 15px;
	width: 100%;
	margin-top: 10px;
	margin-bottom: 10px;
	/* display: flex; */
	box-shadow:
		0px 1px 16px #00000014,
		inset 1px 1px 4px #ffffff40,
		inset -2px -2px 2px #00455c14;
	border-radius: 20px;
	align-items: center;
	text-align: center;
	background-color: #ededed;
}

.attendance-status {
	border-radius: 20px;
	background-color: #0eb770;
	color: white;
	width: 30%;
	justify-content: center;
	margin: auto;
	margin-top: 10px;
}

/* HISTORY */

.history-container {
	/* margin-top: 20px; */
	margin: auto;
	/* padding-left: 20px;
  padding-right: 20px; */
	display: table;
	justify-content: center;
}

.react-calendar {
	width: 285px !important;
	/* display: flex !important; */
	margin: auto !important;
}

/* SUMMARY/ */

.summary-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.summary-header {
	font-family: Hero New;
	font-size: 18px;
	font-weight: 700;
	line-height: 25px;
	letter-spacing: 0em;
	text-align: left;
}

.brand-text {
	font-family: Hero New;
	font-size: 16px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}

.brand-status {
	font-family: Hero New;
	font-size: 16px;
	font-weight: 400;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: center;
	color: #61d544;
}

/* SALES */

.sales-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.invoice-text {
	font-family: Open Sans;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.status-leave-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

/* BONE */

.bone-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

/* SCHEDULE  */

.schedule-container {
	border: 1px solid #ddd;
	padding: 10px;
	margin: 10px;
}

.schedule-header {
	background-color: #f2f2f2;
	padding: 10px;
}

.schedule-column {
	flex: 1;
	text-align: center;
	font-size: 12px;
}

.schedule-entry {
	border-bottom: 1px solid #ddd;
	padding: 10px 0;
}

.schedule-body {
	margin-top: 10px;
}

/* INVENTORY */

.inventory-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

.header-top {
	padding: 20px;
	/* Adjust the padding as needed */
	background: linear-gradient(180deg, #30e700 74.88%, rgba(75, 192, 46, 0) 99.48%);
}

/* UPLOAD RECEIPT */

.upload-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

/* HOMESHELF DISPLAY */

.homeshelf-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

/* RECORD */

.record-container {
	margin-top: 20px;
	padding-left: 20px;
	padding-right: 20px;
}

/* BLOCK */

.block-container {
	margin-top: 20px;
	padding-left: 50px;
	padding-right: 50px;
}

/* ADMIN */

.content {
	margin-left: 220px; /* Adjust as needed */
	padding: 20px; /* Add padding to content */
}

.sidebar {
	width: 200px;
	height: 100%;
	background-color: rgba(0, 42, 58, 1); /* Sidebar background color */
	color: #fff;
	position: fixed;
	top: 0;
	left: 0;
	padding-top: 20px;
	overflow-y: auto;
	z-index: 1000;

	.sidebar-footer {
		bottom: 0;
		position: fixed;
		padding: 15px;
	}

	.sidebar-footer img {
		width: 50%; /* Adjust the width of the footer image as needed */
		/* Add any other styles you want for the footer image */
	}
}

.sidebar-header {
	text-align: center;
	margin-bottom: 20px;
}

.sidebar-header h3 {
	font-size: 24px;
	margin: 0;
	padding: 10px;
}

.sidebar-menu {
	list-style-type: none;
	padding: 0;
}

.sidebar-menu li {
	margin-bottom: 10px;
}

.sidebar-menu a {
	text-decoration: none;
	color: #fff;
	display: block;
	padding: 10px;
	transition: background-color 0.2s;
	border-left: 3px solid transparent;
}

.sidebar-menu a:hover {
	background-color: #000000; /* Highlight on hover */
	border-left: 3px solid #007bff; /* Highlight color on hover */
}

.content {
	margin-left: 220px; /* Adjust as needed */
	padding: 20px; /* Add padding to content */
}

.ant-modal .ant-modal-content {
	background-color: #efefef;
}

/* Add this CSS to your stylesheets or inline styles */
.navbar-container {
	position: fixed;
	bottom: 0;
	width: 100%;
	background-color: #ffffff; /* Set your preferred background color */
	border-top: 1px solid #e0e0e0; /* Optional: Add a border at the top */
	z-index: 1000; /* Optional: Set a z-index to ensure it stays on top of other elements */
}

/* Add the 'navbar-container' class to your existing div wrapping the navbar */
.navlink-active {
	stroke: rgba(60, 128, 43, 1) !important;
	color: rgba(60, 128, 43, 1) !important;
}
